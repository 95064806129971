import React from "react";

const routerList = [
  {
    title: "百万交易员大赛", //推广页 APP内部报名页跳转
    path: "/trader/appjoin",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页
    path: "/trader/introduction",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页 头条
    path: "/trader/juliangtoutiao",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页  抖音
    path: "/trader/juliangdouyin",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页 递推
    path: "/trader/seo007introduction",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页  软文
    path: "/trader/ruanwen/:01",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest2/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页  软文
    path: "/trader/rw/:01",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest2/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页  百度 搜索
    path: "/trader/bdss01",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页  百度 搜索
    path: "/trader/bdss03",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页  百度 搜索
    path: "/trader/bdsdc/:01",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页  百度  原生
    path: "/trader/bdys01",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页  头条 520活动
    path: "/trader/introduction520",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页  抖音
    path: "/trader/juliangdouyin520",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页  百度 搜索
    path: "/trader/bdss520",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页  百度  原生
    path: "/trader/bdys520",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广页  腾讯
    path: "/trader/gdt01",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizePage/index")
    ),
    exact: true,
  },

  {
    title: "百万交易员大赛", //推广页  免费报名 山东院校
    path: "/trader/sddc",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest2/PopularizeShanDongPage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广注册页 顶级
    path: "/trader/reg-top",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest2/TraderRegisterPage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //推广注册页  次级
    path: "/trader/reg_popularize",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest2/PopularizePrivatePage/index")
    ),
    exact: true,
  },

  //竞猜注册页
  {
    title: "百万交易员大赛",
    path: "/trader/regguessing",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest2/TraderGuessingRegisterPage/index")
    ),
    exact: true,
  },
  //竞猜抽奖页
  {
    title: "百万交易员大赛",
    path: "/trader/lotteryguessing",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest2/TarderGuessingLotteryPage/index")
    ),
    exact: true,
  },
  //竞猜答题页
  {
    title: "百万交易员大赛",
    path: "/trader/answerguessing/:type",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest2/TraderGuessingAnswerPage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //张老师推广注册页
    path: "/trader/futuretrader/:type",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest2/PopularizeFutureTraderPage/index")
    ),
    exact: true,
  },
];

export default routerList;
